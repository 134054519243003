.above-the-fold {
  height: 100vh;
  background-image: url('../../assets/services3.jpeg');
  margin-bottom: 40px;

  .container {
    display: inline-block;
    height: 100vh;
    max-width: 100%;
    width: 100%;
    background-color: rgba(35, 35, 35, .5);
    padding-top: 240px;
    z-index: 10;

    @media all and (max-width: 360px) {
      padding-top: 200px;
    }

    div {
      margin: 0 auto;
      h1, h3 {
        font-family: $title;
        font-size: calc(43px + (70 - 43) * ((100vw - 300px) / (1600 - 300)));
        color: $white;
        word-break: break-word;
        margin: 0;
      }

      h1 {
        width: 90%;
        margin: 0 auto;
      }

      h3 {
        margin-top: 20px;
        font-size: calc(30px + (40 - 30) * ((100vw - 300px) / (1600 - 300)));
      }

      h5 {
        font-family: $title;
        line-height: 1.2;
        color: $white;
        word-break: break-word;
      }

      .number-on-banner {
        display: inline-flex;
        align-items: center;
        margin-top: 20px;

        @include phone {
          color: $white;
          font-size: 19px;
          text-decoration: underline;

          img {
            margin-left: 8px;
          }
        }
      }
    }
  }
}

.division {
  position: relative;
  padding-top: 120px;
  padding-bottom: 80px;
  overflow: hidden;

  h1 {
    font-family: $title;
    font-size: calc(40px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
    text-align: center;
    color: $dark-gray;
    margin-top: 0;
  }

  p {
    font-family: $text;
    font-size: 1.2em;
    text-align: justify;
    color: $dark-gray;
  }
}

.services-background {
  background-image: url('../../assets/services3.jpeg');

  h1, h3 {
    font-family: $title;
    font-size: calc(40px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
    text-align: center;
    color: $white;
  }

  .row {
    padding-top: 80px;
    padding-bottom: 80px;
    margin-bottom: 0;
  }

  .services-cards {
    padding-top: 40px;

    h1 {
      color: $dark-gray;
      margin-top: 0;
    }

    .card-image {
      background-position: center;
      background-size: cover;
      height: 400px;
      width: 100%;
    }

    .waterproofing-image {
    background-image: url('../../assets/deck_proofing.jpg');
    }

    .retaining-walls {
      background-image: url('../../assets/retaining_walls.jpeg');
    }

    .balcony-image {
      background-image: url('../../assets/balcony-img.jpeg');
    }

    .stairways-image {
      background-image: url('../../assets/stairways.jpeg');
    }

    .card-content {
      font-family: $text;
      color: $dark-gray;
    }
  }
}

.overlay {
  position: relative;

  img {
    height: 600px;
    width: 600px;

    @include phone {
      width: 100%;
    }
  }
}

.review-logo {
  height: 32px;
  margin-left: 16px;
}

.reviews {
  margin-top: 80px;
  padding: 20px;

  .entry {
    padding: 16px;
    
    div {
      display: flex;
      @include flex-direction(column);
      @include align-items(center);
      background-color: $light-blue;
      padding: 16px;
      border-radius: 8px;

      .username {
        @include flexbox();
        font-family: $title;
        font-size: 1.2em;
      }
  
      p {
        font-family: $text;
        font-size: 1em;
      }
    }
    }
}

.cta-button {
  button {
    position: relative;
    cursor: pointer;
    display: block;
    height: 64px;
    width: 360px;
    font-family: $title;
    font-size: 1.5em;
    letter-spacing: 3px;
    color: $white;
    background-color: $dark-blue;
    border: 0;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 40px;
    z-index: 10;
    transition: 0.3s;

    @include phone {
      width: 100%;
    }

    &:hover {
      background-color: $blue;
      transition: 0.3s;
    }

    img {
      position: relative;
      vertical-align: middle;
      color: $white;
      margin-left: 20px;
    }
  }
}

.map-embed {
  display: block;
  border: none;
  margin: 0 auto;
  width: 80%;

  @include phone {
    width: 100%;
  }
}