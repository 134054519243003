$title: 'Fjalla One', sans-serif;
$text: 'Cantarell', sans-serif;

/* MAIN PALETTE */
$light-blue: #ddebfd;
$aqua: #22d1ee;
$blue: #3d5af1;
$dark-blue: #0e153a;
$whatsapp-green: #00af9c;

$white: #ffffff;
$dark-gray: #232323;

/* MEDIA QUERIES */
$screen-xs-phone:       320px;
$screen-phone:          480px;
$screen-tablet:         768px;
$screen-desktop:        992px;
$screen-lg-desktop:     1200px;
$big-screen:            1440px;
$screen-xlg-desktop:    2440px;