// Custom mixins for responsive design using media queries

/* Variables:

$screen-xs-phone:       320px
$screen-phone:          480px
$screen-tablet:         768px
$screen-desktop:        992px
$screen-lg-desktop:     1200px
$big-screen:            1440px
$screen-xlg-desktop:    2440px

*/

@mixin small-phone {
  @media (max-width: #{$screen-xs-phone}) {
      @content;
  }
}

@mixin phone {
  @media (max-width: #{$screen-phone}) {
      @content;
  }
}

@mixin tablet {
  @media (min-width: #{$screen-phone}) and (max-width: #{$screen-tablet}) {
      @content;
  }
}

@mixin desktop {
  @media (min-width: #{$screen-tablet + 1}) and (max-width: #{$screen-desktop}) {
      @content;
  }
}

@mixin lg-desktop {
  @media (min-width: #{$screen-desktop}) and (max-width: #{$screen-lg-desktop}) {
      @content;
  }
}

@mixin big-screen {
  @media (min-width: #{$screen-lg-desktop}) and (max-width: #{$screen-xlg-desktop}) {
      @content;
  }
}

@mixin extra-lg-desktop {
  @media (min-width: #{$screen-xlg-desktop}) {
      @content;
  }
}