.inner-page-waterproofing {
	background-image: url('../../assets/deck_proofing.jpg');
}

.inner-page-balcony {
	background-image: url('../../assets/balcony-img.jpeg');
}

.inner-page-retaining-walls {
	background-image: url('../../assets/retaining_walls.jpeg');
}

.inner-page-stairways {
	background-image: url('../../assets/stairways.jpeg');
}

.inner-page-banner {
	position: relative;
	height: 90vh;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	
	.inner-service-overlay {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba($color: #232323, $alpha: .3);
	}

	h1 {
		font-family: $title;
		color: $white;
		padding-top: 120px;
		margin: 0;
	}
}