@import url("https://fonts.googleapis.com/css2?family=Cantarell:ital@0;1&family=Fjalla+One&display=swap");

@import "./helpers/variables";
@import "./helpers/mixins-flexbox";
@import "./helpers/mixins-media-queries";

@import "./views/Home.scss";
@import "./views/Services.scss";

/*
CSS formula to scale text to screen size
calc(minsize + (maxsize - minsize) * ((maxViewPortWidth - minWidth) / (maxWidth - minWidth)))
*/

html {
	scroll-behavior: smooth;
}

body {
	padding: 0%;
	margin: 0%;
	font-size: 16px;
	overflow-x: hidden;
}

::selection {
	background: $aqua;
}
::-moz-selection {
	background: $aqua;
}

nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	height: 100px;
	padding-right: 16px;
	background-color: $white;
	z-index: 100;

	.brand-logo {
		top: 0;
		height: 100px;
	}

	button {
		position: inherit;
		background: none;
		border: none;
		vertical-align: middle;

		img {
			height: 40px;
		}
	}
}

.social-icons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100px;

	a {
		display: inherit;
		vertical-align: middle;
	}
}

#slide-out {
	a {
		padding-top: 24px;

		li {
			font-family: $text;
			font-size: 1.2em;
			color: $dark-blue;
			padding: 12px;
			transition: 0.3s;

			&:hover {
				color: $light-blue;
				background-color: $dark-blue;
				transition: 0.3s;
			}
		}
	}
}

.testimonials-cta {
	display: block;
	text-align: center;
}

.fix-background {
	background-position: top;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
}

.send-btn {
	font-family: $text;
	font-size: 1.5em;
	height: 60px;

	img {
		vertical-align: middle;
		margin-left: 20px;
	}
}

strong {
	font-weight: bolder;
}

.fab-trigger {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	bottom: 32px;
	right: 32px;
	z-index: 1000;

	img {
		height: 24px;
	}
}

.modal-content {
	a {
		display: flex;
		align-items: center;
		color: $dark-blue;
		margin: 12px 0;
		transition: .3s;

		img {
			height: 36px;
			margin-right: 16px;
		}
	}

	.sms:hover {
		color: $blue;
		transition: .3s;
	}

	.whatsapp:hover {
		color: $whatsapp-green;
		transition: .3s;
	}
}

/* Footer */

footer {
	background-color: $dark-gray;
	padding: 80px 0 0 0;

	.row {
		margin-bottom: 0;

		.col {
			padding: 0 40px;
			margin-bottom: 40px;

			h1 {
				font-family: $title;
				font-size: 1.7em;
				color: $white;
				margin: 0;
			}

			p,
			a {
				font-family: $text;
				font-size: 1.1em;
				color: $white;
				text-align: left;
			}

			a {
				display: inline-block;
				text-decoration: underline;
				margin-top: 17.6px;
				margin-bottom: 40px;

				img {
					height: 20px;
					vertical-align: middle;
					margin-left: 5px;
				}
			}
		}
	}

	.policy {
		@include flexbox();
		@include justify-content(space-between);
		@include flex-direction(row);
		background-color: #000000;
		padding: 20px 40px;

		@include phone {
			@include flex-direction(column);
			padding: 10px 20px;
		}

		div > a,
		p {
			font-family: $text;
			font-size: 0.9em;
			color: $white;
			text-align: center;
		}

		div {
			@include flexbox();
			@include align-items(center);

			@include phone {
				@include justify-content(center);
			}

			a {
				background: none;
				padding: 0;
				border: none;
				margin-left: 20px;

				@include phone {
					font-size: 0.7em;
					padding: 0 10px;
					margin-left: 0;
				}

				&:hover {
					background: none;
				}
			}
		}
	}
}

.form-success {
	font-family: $title;
	color: $whatsapp-green;
	text-align: center;
}
